import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Settings } from "../models/settings.model";
import { LocalSettingsProvider } from "../services/localSettings.provider";

const settingsProvider = new LocalSettingsProvider();
const SETTINGS_QUERY_KEY = "settings";

export function useSettings() {
  const queryClient = useQueryClient();

  const { data: settings, isLoading } = useQuery({
    queryKey: [SETTINGS_QUERY_KEY],
    queryFn: async () => settingsProvider.getSettings(),
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: true,
  });

  const { mutate: updateSettings } = useMutation({
    mutationFn: async (newSettings: Partial<Settings>): Promise<Settings> => {
      return Promise.resolve(settingsProvider.updateSettings(newSettings));
    },
    onSuccess: (updatedSettings) => {
      queryClient.setQueryData([SETTINGS_QUERY_KEY], updatedSettings);
    },
  });

  const { mutate: resetSettings } = useMutation({
    mutationFn: async (): Promise<Settings> => {
      return Promise.resolve(settingsProvider.resetSettings());
    },
    onSuccess: (defaultSettings) => {
      queryClient.setQueryData([SETTINGS_QUERY_KEY], defaultSettings);
    },
  });

  return {
    settings,
    isLoading,
    updateSettings,
    resetSettings,
  };
}
