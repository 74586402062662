import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  ChevronLeftIcon,
  SettingsIcon,
  EditIcon,
  InfoIcon,
  CheckIcon,
} from "@chakra-ui/icons";
import { useHeader } from "./HeaderContext";
import useAuth from "../store/auth/authState";
import EditModal from "./dialogs/EditModal";
import useAppMode from "../store/appMode/appModeState";
import useI18n from "../hooks/useI18n";

const Header = () => {
  const navigate = useNavigate();
  const { handleGoBack, handleGoHome } = useHeader();
  const { tokens } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isEditMode, toggleEditMode } = useAppMode();
  const { t } = useI18n("auth");

  const isAuthenticated = !!tokens.accessToken;

  const handleProfileClick = () => {
    if (isAuthenticated) {
      navigate("/account/info");
    } else {
      onOpen();
    }
  };

  const handleSettingsClick = () => {
    if (isAuthenticated) {
      navigate("/account/settings");
    } else {
      onOpen();
    }
  };

  const handleEditClick = () => {
    if (isAuthenticated) {
      toggleEditMode();
    } else {
      onOpen();
    }
  };

  return (
    <>
      <Box
        h="30px"
        px={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bg="white"
        borderBottom="1px"
        borderColor="gray.200"
        backgroundColor={isEditMode ? "teal.500" : "white"}
      >
        {/* Left Side - Navigation Icons */}
        <HStack spacing={1}>
          <IconButton
            aria-label="Home"
            icon={<HamburgerIcon color={isEditMode ? "white" : "gray"} />}
            size="xs"
            variant="ghost"
            onClick={handleGoHome}
          />
          {/*<IconButton*/}
          {/*  aria-label="Back"*/}
          {/*  icon={<ChevronLeftIcon />}*/}
          {/*  size="xs"*/}
          {/*  variant="ghost"*/}
          {/*  onClick={handleGoBack}*/}
          {/*/>*/}
        </HStack>

        {/* Right Side - Menu */}
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<SettingsIcon color={isEditMode ? "white" : "gray"} />}
            variant="ghost"
            size="xs"
          />
          <MenuList>
            <MenuItem icon={<InfoIcon />} onClick={handleProfileClick}>
              Profile
            </MenuItem>
            <MenuItem icon={<SettingsIcon />} onClick={handleSettingsClick}>
              Settings
            </MenuItem>
            <MenuItem
              icon={isEditMode ? <CheckIcon /> : <EditIcon />}
              onClick={handleEditClick}
            >
              {isEditMode ? t("finish_edit_category") : t("edit_category")}
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>

      {/* Edit Modal for unauthorized users */}
      <EditModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Header;
